import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../../layouts'
import LargeHero from '../../../components/elements/largeHero'
import { Body, SizedText, Canon } from '../../../components/elements/typography'
import Grid from '../../../components/elements/grid'
import { navigate } from 'gatsby'

import { useForm, useFormState } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import Botpoison from "@botpoison/browser"

function encode(data) {
  return Object.keys(data)
      .map(
          key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&')
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const schema = yup.object({
  fullName: yup.string()
    .required('Your name is required.')
    .min(4, 'Please enter your full name.')
    .max(80, 'Please enter a valid name.'),
  phoneNumber: yup.string().matches(phoneRegExp, 'Phone number is not valid. (Format: 912-739-3322)')
    .min(10, 'Please include your area code.')
    .max(12, 'Please enter a valid phone number. (Format: 912-739-3322)'),
  emailAddress: yup.string().email("Please enter a valid email address."),          
}).required();

const ContactMessagePage = () => {

  const { register, handleSubmit, watch, reset, formState: { errors, isSubmitted, isDirty, isValid } } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema)
  })




  const onSubmit = (data, e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
            'form-name': 'general-inquiry-form',
            ...data,
        }),
    })
        .then(response => {
            reset()
            navigate(form.getAttribute('action'))
            console.log(response)
        })
        .catch(error => {
            console.log(error)
        })
}

  return (
    <Layout isSubMenuOpen={true} openAccountLink={true}>

      <LargeHero.Container
        padding="3rem 0 4rem 0"
        background={{
          backgroundImage: `url(/images/background-deltas.jpg)`, 
          backgroundRepeat: `no-repeat`, 
          backgroundAttachment: `scroll`, 
          backgroundPosition: `top left`, 
          backgroundSize: `cover`,
        }}
        breadcrumbLinks={[
          { path: "/", text: "Home" },
          { path: "/about/", text: "About Us" },
          { path: "/about/contact", text: "Contact Us" },
          { path: "/about/contact/message", text: "Send a Message" },
        ]}
        styles={{
          paddingBottom: `5rem`,
          overflow: `hidden`,
          '.breadcrumbs, .breadcrumbs [aria-current="page"]': {color: `#fff`},
          '.heroContent': { paddingBottom: `3rem`},
          '@media (min-width: 680px)': {
            '.heroContent': { width: `calc(52% - 1rem)`, marginBottom: `0`},
            '.heroImage': { width: `calc(40% - 1rem) !important`,},
          }
        }}
      >
        <LargeHero.Content styles={{paddingTop: `1rem`, color: `#fff`}}>
          <LargeHero.Eyebrow text="About Us" styles={{borderColor: `#fff !important`}}/>
          <h1 css={{fontFamily: `Proxima-nova`, textTransform: `uppercase`,  'span': {display: `block`, lineHeight: `1`, padding: `0.5rem 0`}}}>
            <SizedText as="span" smallSize="2.281" largeSize="4.624">Send us a Message</SizedText>
          </h1>
          </LargeHero.Content>
          <LargeHero.Image >

          </LargeHero.Image>
      </LargeHero.Container>

      <Grid.FullWidthSection css={{padding: `6rem 0`, textAlign: `center`}}>
        <Grid.Block start="3" span="10" css={{paddingBottom: `2rem`}}>
          <Canon>Need Some Help? <br />Contact a Team Member Today.</Canon>
          <Body>
            Our team members are here to help.  Please fill out the fields below, and a subject matter expert will get back to you soon.<br />
            NOTE: Please <b css={{textDecoration: `underline`}}>DO NOT</b> include any sensitive information, like your debit card number or PIN, account number, or social in your message.
          </Body>
        </Grid.Block>
        <Grid.Block start="5" span="6">
        <form 
          /* onSubmit={handleSubmit(onSubmit)} */
          name="general-inquiry-form"
          method="POST"
          action="https://submit-form.com/NJmuG3Ea"
          data-botpoison-public-key="pk_bd6ae44d-ead4-4b3f-a617-5c40b1182888"
          css={{
            maxWidth: `550px`,
            margin: `0 auto`,
            'form, input, label, textarea': {
              boxSizing: `border-box`,
	            display: `block`,
              textAlign: `left`,
            },
            '.gfFieldBlock': {
              position: `relative`,
              border: `1px solid #aeaeae`,
              backgroundColor: `#fff`,
              borderRadius: `0.5rem`,
              marginBottom: `1rem`,
            },
            'label, input, textarea': {
              transition: `color 0.4s ease, background-color 0.1s ease-in-out`,
            },
            'label': {
              color: `#aeaeae`,
              position: `absolute`,
              top: `10px`,
              left: `10px`,
              fontSize: `0.889rem`
            },
            'input:focus + label': {
              color: `#0059a6`,
              fontWeight: `bold`,
            },
            'input, textarea': {
              backgroundColor: `transparent`,
              border: `none`,
              color: `#626262`,
              margin: `9px 0 7px 0`,
              margin: `0`,
              padding: `2.2em 10px 10px 10px`,
              outline: `none`,
              width: `100%`,
              borderRadius: `0.5rem`,
              fontSize: `1rem`,
              fontFamily: `source-sans-pro,"Segoe UI",Tahoma,Geneva,sans-serif`
            },
            'input:focus, textarea:focus': {
              color: `#000`,
              backgroundColor: `#eeeeee`,
              boxShadow: `0px 3px 4px -2px rgba(0, 0, 115, 0.25) inset`,
            }
          }}
        >
          <input
    type="hidden"
    name="_redirect"
    value="https://tcbga.bank/about/contact/success"
  />
          <div className='gfFieldBlock'>
            <input id="full-name" {...register("fullName")} placeholder="John Smith" />
						<label for="full-name">Full Name *</label>
            <div className="errorMessage">
              <small>{errors.fullName?.message}</small>
            </div>
          </div>

          <div className='gfFieldBlock'>
            <input id="phone-number" {...register("phoneNumber")} placeholder="912-739-3322" />
						<label for="phone-number">Phone Number *</label>
            <div className="errorMessage">
              <small>{errors.phoneNumber?.message}</small>
            </div>
          </div>

          <div className='gfFieldBlock'>
            <input id="email-address" {...register("emailAddress")} placeholder="info@tcbga.bank" />
						<label for="email-address">Email Address *</label>
            <div className="errorMessage">
              <small>{errors.emailAddress?.message}</small>
            </div>
          </div>

          <div className='gfFieldBlock'>
            <input id="subject" {...register("subject")} placeholder="I Lost My Debit Card" />
						<label for="subject">Message Subject *</label>
            <div className="errorMessage">
              <small>{errors.subject?.message}</small>
            </div>
          </div>

          <div className='gfFieldBlock'>
            <textarea id="content" {...register("content")} placeholder="I Lost My Debit Card" css={{resize: `none`}} rows="10"/>
						<label for="content">Message Content *</label>
            <div className="errorMessage">
              <small>{errors.content?.message}</small>
            </div>
          </div>

          <button
          type="submit"
          disabled={!isDirty || !isValid} 
          css={{
            margin: `1rem 0`,
            padding: `0.75rem 2rem`,
            backgroundColor: `#2ecc71`,
            borderRadius: `0.5rem`,
            border: `1px solid #27ae60`,
            color: `#fff`,
            outline: `none`,
            width: `100%`,
            maxWidth: `400px`,
            transition: `all 1s ease`,
            cursor: `pointer`,
            '&:hover': {
              backgroundColor: `#d2ded7`,
              borderColor: `#91ad9d`
            },
            '&:disabled': {
              backgroundColor: `#d2ded7`,
              borderColor: `#91ad9d`
            }
          }}
        >
          Submit Form
        </button>

        </form>
        </Grid.Block>
      </Grid.FullWidthSection>

    </Layout>
  )
}

export default ContactMessagePage